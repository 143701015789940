import axios from 'axios'
import {_request,getRequest} from './Axios'
const instance = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    timeout: 30000,
});

const AuthClient = {
    login: async(payload) => {
        try {           
            // console.log(payload)
            let response = await instance.post('/auth/riderLogin',payload);
            return response.data;
        } catch(error) {
           if (error.response) {
               console.log(error.response.data)
               throw new Error(error.response.data.error.message);
           } else {
               console.log(error);
               throw new Error(error.message);
           }
        }
    },


    checkEmail: async(payload) => {
        try {
            let response = await instance.post('/auth/checkRiderEmail',payload);
            return response.data;
        } catch(error) {
           if (error.response) {
                console.log(error)
               throw new Error(error.response.data.error.message);
           } else {
               throw new Error(error.message);
           }
        }
    },
}

export default AuthClient